<template>
  <div class="youth-homes" ref="printMe"  v-if="hasPerm('youth.view_seanceinscription')">
    <page-header
      :title="title"
      icon="fa fa-children"
      :links="getLinks"
      class="no-print">
    </page-header>
    <b-row class="sub-header page-left-title">
      <b-col cols="4">
        <b>
          {{ weekName }}
          <span class="hide-here">-</span>
        </b>
      </b-col>
      <b-col cols="5">
        <div v-if="!isLoading(loadingName)">
          <b>
            {{ getEltName() }}
          </b>
        </div>
      </b-col>
      <b-col cols="3" class="text-right">
        <span class="hide-here">-</span>
        <b>
          <counter-label
            :counter="filteredElements.length"
            label="inscrit" v-if="!isLoading(loadingName)"
          >
          </counter-label>
        </b>
      </b-col>
    </b-row>
    <b-row class="no-print" v-if="youthHomeElt && youthHomeElt.id">
      <b-col cols="3"></b-col>
      <b-col>
        <b-form-checkbox
          id="includeAllChildren"
          v-model="includeAllChildren"
          class="no-print"
        >
          <b>Afficher tous les inscrits à {{ youthHomeElt.name }}</b>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <div class="hide-here" v-if="printDate">Imprimé le {{ printDate }}</div>
    <div class="no-print">
      <b-row class="sub-header">
        <b-col cols="3">
          <b-form-select
            id="group-by"
            v-model="groupBy"
          >
            <b-form-select-option :value="elt" v-for="elt in groupByChoices" :key="elt.id">
              {{ elt.name }}
            </b-form-select-option>
          </b-form-select>
          <b-form-checkbox
            id="ignoreNoGroup"
            v-model="ignoreNoGroup"
            class="no-print"
            v-if="groupByLabel"
          >
            seulement si {{ groupByLabel }}
          </b-form-checkbox>
        </b-col>
        <b-col cols="1">
          <b-form-group
            id="parents-group"
            label=""
            label-for="parents"
          >
            <b-form-checkbox
              id="parents"
              v-model="showParents"
              :value="true"
              :unchecked-value="false"
            >
              Parents
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="1">
          <b-form-group
            id="fields-group"
            label=""
            label-for="fields"
          >
            <b-form-checkbox
              id="fields"
              v-model="showFields"
              :value="true"
              :unchecked-value="false"
            >
              Données
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-select
            id="seance-filter"
            v-model="seancesFilterId"
            v-show="seancesFilters.length > 1"
            :class="(seancesFilterId > 0) ? 'highlighted' : ''"
          >
            <b-form-select-option :value="elt.id" v-for="elt in seancesFilters" :key="elt.id">
              {{ elt.name }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="2" class="text-right">
          <b-form-select
            id="display-by"
            v-model="displayById"
          >
            <b-form-select-option :value="elt.id" v-for="elt in displayBys" :key="elt.id">
              {{ elt.name }}
            </b-form-select-option>
          </b-form-select>
          <set-time-selector
            v-if="clocking"
            v-model="clockStatus"
            :hide-direct="true"
          ></set-time-selector>
        </b-col>
        <b-col cols="2" class="text-right">
          <b-form-group
            id="pageBreak-group" label="" label-for="pageBreak"
            style="border: solid 1px #f0f0f0; padding: 2px; border-radius: 4px;"
          >
            <div class="help-text">Impression PDF</div>
            <b-form-checkbox id="pageBreak" v-model="pageBreak">
              Sauts de page
            </b-form-checkbox>
            <b-form-checkbox id="landscape" v-model="landscape">
              Paysage
            </b-form-checkbox>
          </b-form-group>
          <b-form-group id="showTotal-group" label="" label-for="showTotal">
            <b-form-checkbox id="showTotal" v-model="showTotal">
              Affichage des totaux
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div ref="excelMe">
      <loading-gif :loading-name="loadingName"></loading-gif>
      <div v-if="!isLoading(loadingName)">
        <div class="doc">
          <table class="table-full table-bordered table-total" :style="tableStyle" v-if="showTotal">
            <tr class="table-header">
              <th :style="getFirstColStyle(null)">Total</th>
              <th v-if="showParents" :style="secondColStyle">&nbsp;</th>
              <th
                v-for="day of visibleDays"
                :key="day"
                class="td-day text-center"
                :style="dayColStyle(day)"
                :class="dayClass(day)"
                :colspan="presence ? presenceItems.length : (duration ? 2 : 1)"
              >
                {{ day }}
              </th>
            </tr>
            <tr class="table-header" v-if="!presence && !duration">
              <th></th>
              <th v-if="showParents"></th>
              <th
                v-for="day of visibleDays"
                :key="day"
                class="td-day text-center"
                :style="dayColStyle(day)"
                :class="dayClass(day)"
              >
                <moments-counter :total-moments="dayCounter(day)" :has-lunch="hasLunch"></moments-counter>
              </th>
            </tr>
            <tr class="table-header" v-if="duration">
              <th></th>
              <th v-if="showParents"></th>
              <th
                v-for="col of durationVisibleDays"
                :key="col.key"
                :style="dayColStyle(col.day)"
                :class="dayClass(col.day)"
                class="number"
              >
                {{ getDurationLabel(col) }}
              </th>
            </tr>
            <tr class="table-header" v-if="duration">
              <th></th>
              <th v-if="showParents"></th>
              <th
                v-for="col of durationVisibleDays"
                :key="col.key"
                :style="dayColStyle(col.day)"
                :class="dayClass(col.day)"
                class="number"
              >
                {{ getDurationTotal(col) }}
              </th>
            </tr>
            <tr class="table-header" v-if="presence">
              <th></th>
              <th v-if="showParents"></th>
              <th
                v-for="col of presenceItemsVisibleDays"
                :key="col.key"
                class="rotate-90 no-overflow"
                :class="dayClass(col.day)"
                :style="presenceStyle(col.day)"
              >
                <span class="no-print">{{ col.item.label }}</span>
                <span v-if="printMode" class="hide-here small">{{ col.item.shortLabel() }}</span>
              </th>
            </tr>
            <tr class="table-header table-vertical" v-if="presence">
              <th></th>
              <th v-if="showParents"></th>
              <th
                v-for="col of presenceItemsVisibleDays"
                :key="col.key"
                style="text-align: center;"
                :class="dayClass(col.day)"
                :style="presenceStyle(col.day)"
                class="number"
              >
                {{ getPresenceTotal(col) }}
              </th>
            </tr>
          </table>
        </div>
        <div v-for="superGrouper of elementsByGroups" :key="superGrouper.id" class="super-grouper doc">
          <table class="table-full table-striped table-bordered page">
            <tbody
              v-for="(grouper, grouperIndex) of getSuperGrouperGroups(superGrouper)"
              :key="grouper.id"
              :style="superGrouper.showHeader ? superTableStyle : tableStyle"
              :class="(pageBreak && pakeBreakOnGroup) ? 'page' : ''"
            >
              <tr class="table-header" v-if="grouperIndex === 0 && !pageBreak">
                <th :style="getFirstColStyle(null)">&nbsp;</th>
                <th v-if="showParents" :style="secondColStyle"></th>
                <th
                  v-for="day of visibleDays"
                  :key="day"
                  class="td-day text-center"
                  :style="dayColStyle(day)"
                  :colspan="presence ? presenceItems.length : (duration ? 2 : 1)"
                  :class="dayClass(day)"
                >
                  {{ day }}
                </th>
              </tr>
              <tr
                class="table-header table-vertical"
                v-if="presence && (grouperIndex === 0) && !pageBreak"
              >
                <th :style="getFirstColStyle(null)"></th>
                <th :style="secondColStyle" v-if="showParents"></th>
                <th
                  v-for="col of presenceItemsVisibleDays"
                  :key="col.key"
                  class="rotate-90 no-overflow"
                  :class="dayClass(col.day)"
                  :style="presenceStyle(col.day)"
                >
                  <span class="no-print">{{ col.item.label }}</span>
                  <span v-if="printMode" class="hide-here small">{{ col.item.shortLabel() }}</span>
                </th>
              </tr>
              <tr
                class="super-grouper-header"
                v-if="!presence && superGrouper.showHeader && (grouperIndex === 0)"
              >
                <th :style="getFirstColStyle(null)">
                  {{ superGrouper.name }}
                </th>
                <th :style="secondColStyle">&nbsp;</th>
                <th :colspan="visibleDays.length"></th>
              </tr>
              <tr
                class="super-grouper-header"
                v-if="presence && superGrouper.showHeader && (grouperIndex === 0)"
              >
                <th :style="getFirstColStyle(null)">
                  {{ superGrouper.name }}:
                  <counter-label :counter="getSuperGrouperCount(superGrouper)" label="inscrit"></counter-label>
                </th>
                <th v-if="showParents" :style="secondColStyle"></th>
                <th
                  v-for="col of presenceItemsVisibleDays"
                  :key="col.key"
                  :style="presenceStyle(col.day)"
                  :class="dayClass(col.day)"
                  class="number"
                >
                  {{ getSuperPresenceSum(col, superGrouper) }}
                </th>
              </tr>
              <tr
                class="table-header"
                v-if="pageBreak"
              >
                <th :style="getFirstColStyle(grouper)">&nbsp;</th>
                <th v-if="showParents" :style="secondColStyle"></th>
                <th
                  v-for="day of visibleDays"
                  :key="day"
                  class="td-day text-center"
                  :style="dayColStyle(day)"
                  :colspan="presence ? presenceItems.length : (duration ? 2 : 1)"
                  :class="dayClass(day)"
                >
                  {{ day }}
                </th>
              </tr>
              <tr
                class="table-header table-vertical"
                v-if="presence && pageBreak"
              >
                <th :style="getFirstColStyle(grouper)"></th>
                <th :style="secondColStyle" v-if="showParents"></th>
                <th
                  v-for="col of presenceItemsVisibleDays"
                  :key="col.key"
                  class="rotate-90 no-overflow"
                  :class="dayClass(col.day)"
                  :style="presenceStyle(col.day)"
                >
                  <span class="no-print">{{ col.item.label }}</span>
                  <span v-if="printMode" class="hide-here small">{{ col.item.shortLabel() }}</span>
                </th>
              </tr>
              <tr class="table-header" v-if="!presence && !duration">
                <th>{{ grouper.name }}</th>
                <th v-if="showParents"></th>
                <th
                  v-for="day of visibleDays"
                  :key="day"
                  class="td-day text-center"
                  :style="dayColStyle(day)"
                  :class="dayClass(day)"
                >
                  <moments-counter :total-moments="groupAndDayCounter(grouper, day)" :has-lunch="hasLunch">
                  </moments-counter>
                </th>
              </tr>
              <tr v-if="presence" class="table-header">
                <th :style="getFirstColStyle(grouper)">
                  {{ grouper.name }}:
                  <counter-label :counter="grouper.elements.length" label="inscrit"></counter-label>
                </th>
                <th v-if="showParents"></th>
                <th
                  v-for="col of presenceItemsVisibleDays"
                  :key="col.key"
                  :style="presenceStyle(col.day)"
                  :class="dayClass(col.day)"
                  class="number"
                >
                  {{ getPresenceSum(col, grouper.elements) }}
                </th>
              </tr>
              <tr class="table-header table-vertical" v-if="duration && (grouperIndex === 0)">
                <th></th>
                <th v-if="showParents"></th>
                <th
                  v-for="col of durationVisibleDays"
                  :key="col.key"
                  :class="dayClass(col.day)"
                  :style="presenceStyle(col.day)"
                >
                  {{ getDurationLabel(col) }}
                </th>
              </tr>
              <tr class="table-header" v-if="duration && (grouperIndex === 0)">
                <th></th>
                <th v-if="showParents"></th>
                <th
                  v-for="col of durationVisibleDays"
                  :key="col.key"
                  class="number"
                  :style="dayColStyle(col.day)"
                  :class="dayClass(col.day)"
                >
                  {{ getDurationSum(col, grouper) }}
                </th>
              </tr>
              <tr class="table-header" v-if="showClockingCols && (grouperIndex === 0)">
                <th></th>
                <th v-if="showParents"></th>
                <th
                  v-for="day of visibleDays"
                  :key="day"
                  class="td-day text-center"
                  :class="dayClass(day)"
                  :style="dayColStyle(day)"
                >
                  <clocking-columns :clocking-columns="clockingColumns" caption>
                  </clocking-columns>
                </th>
              </tr>
              <tr v-for="elt in grouper.elements" :key="elt.individual.id" class="row-line">
                <td :style="getFirstColStyle(grouper)">
                  <inscription-fields :element="elt" :fields="markFields" show-as-mark v-if="showFields">
                  </inscription-fields>
                  {{ elt.individual.lastName }} {{ elt.individual.firstName }}
                  <span
                    v-if="showFields"
                    class="tiny-badge badge-dark"
                    v-b-tooltip="elt.individual.birthDateAsString()"
                   >
                    <span @click.prevent="toggleBirthDate()" class="clickable show-birth-date">
                      <span v-if="showAge">{{ getAgeAtSeance(elt.individual) }} ans</span>
                      <span v-if="hasCustomGroup(elt)"> -> {{ getCustomGroup(elt) }}</span>
                      <span v-if="showAge && showBirthDate"> - </span>
                      <span v-if="showBirthDate">{{ elt.individual.birthDateAsString() }}</span>
                    </span>
                  </span>
                  <div v-if="showFields">
                    <div
                      class="small words"
                      v-if="(!youthHomeElt.hideHandicapOnList) && (elt.child.handicapHelp || elt.child.personalHelp)"
                    >
                      <span v-if="elt.child.personalHelp">PAI</span>
                      <span v-if="elt.child.handicapHelp">AEEH</span>
                    </div>
                    <div class="small2" v-if="elt.individual.about && !options.hideIndividualAbout">
                      {{ elt.individual.about }}
                    </div>
                    <div class="small highlight" v-if="elt.child.warnings && !options.hideChildWarning">
                      {{ elt.child.warnings }}
                    </div>
                    <inscription-fields :element="elt" :fields="valueFields"></inscription-fields>
                  </div>
                </td>
                <td :style="secondColStyle" v-if="showParents">
                  <div v-for="parent in elt.parents" :key="parent.id" class="small2">
                    {{ parent.lastAndFirstName() }} :
                    {{ getPhoneNumber(elt, parent) }}
                  </div>
                </td>
                <td
                  v-for="col of presenceItemsVisibleDays"
                  :key="col.key"
                  :style="presenceStyle(col.day)"
                  :class="dayClass(col.day)"
                  class="number"
                >
                  {{ getPresence(col, elt) }}
                </td>
                <td
                  v-for="col of durationVisibleDays"
                  :key="col.key"
                  :style="presenceStyle(col.day)"
                  :class="dayClass(col.day)"
                  class="number"
                >
                  {{ getDurationValue(col, elt) }}
                </td>
                <td
                  v-for="day of tdVisibleDays"
                  :key="day"
                  class="td-day text-center"
                  :style="dayCellStyle(day)"
                  :class="dayClass(day)"
                >
                  <span v-if="!presence && noInscriptionOnThisDay(elt, day)">
                  </span>
                  <span v-else-if="!presence && notInGroup(grouper, elt, day)">
                    <span style="font-size: 11px; color: #888;">
                      <i class="fa fa-warning"></i> Autre groupe
                    </span>
                  </span>
                  <span v-else-if="absence">
                    <span class="absence-indicator-holder">
                      <div v-for="inscription of onlyDailyInscriptions(elt.inscriptions, day)" :key="inscription.id">
                        <span :style="seanceNameStyle">{{ inscription.seance.getShortName() }}</span>
                        <absence-indicator
                         :inscription="inscription"
                         :individual="elt.individual"
                         @updated="onRefreshInscription(elt, $event)"
                        ></absence-indicator>
                      </div>
                    </span>
                  </span>
                  <span v-else-if="clocking">
                    <div
                      v-for="inscription of onlyDailyInscriptions(elt.inscriptions, day)"
                      :key="inscription.id"
                    >
                      <span :style="seanceNameStyle">{{ inscription.seance.getShortName() }}</span>
                      <span v-if="inscription.absence || inscription.cancelledOn">
                        <absence-indicator
                         :inscription="inscription"
                         :individual="elt.individual"
                         @updated="onRefreshInscription(elt, $event)"
                        ></absence-indicator>
                      </span>
                      <span v-else>
                        <time-selector
                          :inscription="inscription"
                          :opening-hours="getOpeningHours(inscription.seance.date)"
                          :clock-status="clockStatus"
                          @prefixClicked="toggleClockSelector()"
                        >
                        </time-selector>
                      </span>
                    </div>
                  </span>
                  <span v-else-if="showClockingCols">
                    <clocking-columns :clocking-columns="seanceTypeElt.clockingColumns">
                    </clocking-columns>
                  </span>
                  <span v-else>
                    <span
                      :title="moment.label"
                      v-for="moment of getDailyPresences(grouper, elt, day)"
                      :key="moment.index"
                      class="presences-indicator"
                    >
                      <i class="far fa-check-square" v-if="moment.value === 1"></i>
                      <i class="far fa-square" v-if="moment.value === 0"></i>
                      <i class="fa fa-exclamation-triangle" v-if="moment.value === 2"></i>
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-modal
      dialog-class="modal-md"
      id="bv-modal-edit-weeks-number"
      @ok.prevent="onSaveWeeksNumber"
      :ok-disabled="weeksNumberFormInvalid"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Modifier"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-pen"></i> Nombre de semaines à afficher</b>
      </template>
      <b-form-group
         id="weeks-group"
         label="Nombre de semaines"
         label-for="weeks-number"
         description="Les semaines hors-période sont ignorées"
      >
        <b-form-input
          id="weeks-number"
          v-model="weeksNumber"
          type="number"
          min="1"
          max="20"
        >
        </b-form-input>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import InscriptionFields from '@/components/Youth/SeanceInscriptionList/InscriptionFields.vue'
import AbsenceIndicator from '@/components/Youth/AbsenceIndicator'
import TimeSelector from '@/components/Seances/TimeSelector.vue'
import SetTimeSelector from '@/components/Seances/SetTimeSelector.vue'
import ClockingColumns from '@/components/Youth/SeanceInscriptionList/ClockingColumns.vue'
import { dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { makeChild } from '@/types/families'
import { makeIndividual } from '@/types/people'
import { makeSchoolClass, makeSchoolLevelGroup } from '@/types/schools'
import {
  DayMoments, makeDailyListField, makeSeanceInscription, makeAgeGroup, getAgeGroup, makeYouthHome,
  makeSeanceType, makeSeancePeriod, getCustomAge, GroupByChoice, groupByChoices, DayTimeValues,
  makeOpeningHours, makeListingPresenceItem, makeDayListOptions, Excursion, getAgeAtSeance
} from '@/types/youth'
import { BackendApi, openDocument } from '@/utils/http'
import { getWeekDays, getNWeeks, isInTimeframe } from '@/utils/dates'
import { distinct, existsIn } from '@/utils/arrays'
import { sum } from '@/utils/math'
import MomentsCounter from '@/components/Youth/MomentsCounter'
import { compareNumbers } from '@/utils/sorting'
import store from '@/store'
import { makeChoice } from '@/types/base'
import { calculatePaidDuration, calculateRealDuration } from '@/utils/reports'
import { makeCafModel } from '@/types/reports'

const groupByKey = 'youth-week-group-by'
const ignoreNoGroupKey = 'youth-week-no-group'
const includeAllChildrenKey = 'youth-week-include-all'
const landscapeKey = 'youth-week-landscape'
const pageBreakKey = 'youth-week-page-break'
const showTotalKey = 'youth-week-show-total'
const showParentsKey = 'youth-week-show-parents'
const showFieldsKey = 'youth-week-show-fields'

export default {
  name: 'youth-home-week',
  mixins: [BackendMixin],
  components: {
    InscriptionFields,
    AbsenceIndicator,
    SetTimeSelector,
    TimeSelector,
    ClockingColumns,
    MomentsCounter,
    CounterLabel,
    LoadingGif,
    PageHeader,
  },
  props: {
    day: String,
    youthHome: String,
    seanceType: String,
    seancePeriod: String,
  },
  data() {
    return {
      nameFilter: '',
      loadingName: 'youth-home-day',
      seanceDateAsString: '',
      elements: [],
      fields: [],
      schoolClasses: [],
      schoolLevelGroups: [],
      ageGroups: [],
      seances: [],
      workshops: [],
      groupBy: null,
      printMode: false,
      youthHomeElt: makeYouthHome(),
      seanceTypeElt: makeSeanceType(),
      seancePeriodElt: makeSeancePeriod(),
      selectedMoment: DayMoments.None,
      countByDaysAndGroup: new Map(),
      pageBreak: true,
      landscape: true,
      showTotal: true,
      weeksNumber: 0,
      openingHoursMap: new Map(),
      clockStatus: 0,
      showParents: true,
      showFields: true,
      dateFormat: 'ddd DD/MM',
      showAge: true,
      showBirthDate: false,
      printDate: '',
      presenceItems: [],
      displayById: 1,
      cafModelsLoaded: false,
      cafModelsMap: new Map(),
      groupByChoices: groupByChoices(true),
      inscriptionsCount: 0,
      includeAllChildren: false,
      ignoreNoGroup: false,
      options: makeDayListOptions(),
      seancesFilterId: 0,
      inscriptionsLoaded: false,
    }
  },
  watch: {
    loading: function(newValue, oldValue) {},
    groupBy: function() {
      window.localStorage.setItem(groupByKey, '' + this.groupBy.id)
    },
    landscape: function() {
      window.localStorage.setItem(landscapeKey, this.landscape ? 'landscape' : 'portrait')
    },
    showTotal: function() {
      window.localStorage.setItem(showTotalKey, this.showTotal ? 'yes' : 'no')
    },
    showParents: function() {
      window.localStorage.setItem(showParentsKey, this.showParents ? 'yes' : 'no')
    },
    showFields: function() {
      window.localStorage.setItem(showFieldsKey, this.showFields ? 'yes' : 'no')
    },
    pageBreak: function() {
      window.localStorage.setItem(pageBreakKey, this.pageBreak ? 'yes' : 'no')
    },
    seancesFilterId: function() {
      this.refreshCounters()
    },
    seancesFilters: function() {
      if (this.inscriptionsLoaded) {
        const notFound = this.seancesFilters.map(elt => elt.id).indexOf(this.seancesFilterId) < 0
        if (notFound) {
          this.seancesFilterId = 0
        }
      }
    },
    includeAllChildren: function() {
      window.localStorage.setItem(includeAllChildrenKey, this.includeAllChildren ? 'Oui' : '')
      this.loadInscriptions()
    },
    ignoreNoGroup: function() {
      window.localStorage.setItem(ignoreNoGroupKey, this.ignoreNoGroup ? 'Oui' : '')
    },
    elementsByGroups: function() {
      this.refreshCounters()
    },
    duration: function() {
      this.loadCafModels()
    },
  },
  computed: {
    presenceItemsVisibleDays() {
      const items = []
      if (this.presence) {
        for (const day of this.visibleDays) {
          for (const item of this.presenceItems) {
            items.push(
              { key: '' + day + '-' + item.id, item: item, day: day, }
            )
          }
        }
      }
      return items
    },
    durationVisibleDays() {
      const items = []
      if (this.duration && this.cafModelsLoaded) {
        for (const day of this.visibleDays) {
          items.push({ key: '' + day + '-' + 1, item: 1, day: day, })
          items.push({ key: '' + day + '-' + 2, item: 2, day: day, })
        }
      }
      return items
    },
    tdVisibleDays() {
      if (this.presence || this.duration) {
        return []
      } else {
        return this.visibleDays
      }
    },
    clocking() {
      return this.displayById === 2
    },
    absence() {
      return this.displayById === 3
    },
    duration() {
      return this.displayById === 6
    },
    showClockingCols() {
      return this.clockingColumns.length && this.displayById === 4
    },
    presence() {
      return this.presenceItems.length && this.displayById === 5
    },
    displayBys() {
      const displayBys = [makeChoice({ id: 1, name: 'Moments', })]
      if (this.hasOpeningHours) {
        displayBys.push(makeChoice({ id: 2, name: 'Pointage', }))
      }
      displayBys.push(makeChoice({ id: 3, name: 'Absences', }))
      if (this.clockingColumns.length) {
        displayBys.push(makeChoice({ id: 4, name: 'Impression', }))
      }
      if (this.presenceItems.length) {
        displayBys.push(makeChoice({ id: 5, name: 'Plages', }))
      }
      if (this.seanceTypeElt.showDurationOnListing) {
        displayBys.push(makeChoice({ id: 6, name: 'Durées', }))
      }
      return displayBys
    },
    seancesFilters() {
      let choices = [makeChoice({ id: 0, name: 'Toutes les séances', })]
      const hasTrips = this.seances.filter(seance => seance.excursion === Excursion.Trip).length > 0
      const hasStays = this.seances.filter(seance => seance.excursion === Excursion.Stay).length > 0
      if (hasTrips) {
        choices = choices.concat(
          [
            makeChoice({ id: 1, name: 'Sorties', }),
            makeChoice({ id: 2, name: 'Exclure les sorties', })
          ]
        )
      }
      if (hasStays) {
        choices = choices.concat(
          [
            makeChoice({ id: 3, name: 'Séjours', }),
            makeChoice({ id: 4, name: 'Exclure les séjours', })
          ]
        )
      }
      if (hasStays && hasTrips) {
        choices = choices.concat(
          [
            makeChoice({ id: 5, name: 'Sorties et Séjours', }),
            makeChoice({ id: 6, name: 'Exclure les sorties et séjours', })
          ]
        )
      }
      return choices
    },
    seanceNameStyle() {
      return { 'font-size': '11px', }
    },
    title() {
      if (this.seanceTypeElt) {
        if (this.seanceTypeElt.isWeeklyListVisible()) {
          return 'Listing hebdomadaire'
        } else {
          if (this.seanceTypeElt.weeksNumber) {
            return 'Listing ' + this.seanceTypeElt.weeksNumber + ' semaines'
          }
        }
      }
      return 'Listing'
    },
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    hasEvening() {
      return this.seances.filter(seance => seance.evening).length > 0
    },
    hasPicnic() {
      return this.seances.filter(seance => seance.lunch && seance.picnic).length > 0
    },
    hasLunch() {
      const hasPicnic = this.seances.filter(seance => seance.lunch && seance.picnic).length > 0
      const hasLunch = this.seances.filter(seance => seance.lunch && !seance.picnic).length > 0
      return hasLunch || !hasPicnic
    },
    weeksNumberFormInvalid() {
      return (this.weeksNumber <= 0)
    },
    weekName() {
      const range = this.visibleDates
      const format1 = 'ddd D MMM'
      const format2 = 'ddd D MMM YYYY'
      let name = '---'
      let week = ''
      if (range.length > 1) {
        name = 'Du ' + dateToString(range[0], format1) + ' au ' + dateToString(range[range.length - 1], format2)
        if (dateToString(range[0], 'W') === dateToString(range[1], 'W')) {
          week = 'Semaine ' + dateToString(range[0], 'W') + ' - '
        }
      } else if (range.length === 1) {
        name = dateToString(range[0], format2)
      }
      return week + name
    },
    seanceDate() {
      return moment(this.seanceDateAsString, 'YYYY-MM-DD').toDate()
    },
    range() {
      let range = []
      if (this.seanceTypeElt.isWeeklyListVisible()) {
        range = getWeekDays(this.day, !this.seanceTypeElt.wednesdayInWeek())
      } else if (this.seanceTypeElt.isSeveralWeeksVisible()) {
        const nWeeksRange = getNWeeks(this.day, this.seanceTypeElt.weeksNumber)
        const timeframe = this.seancePeriodElt.getTimeframe(this.youthHomeElt.schoolYear.id)
        if (timeframe.length) {
          range = nWeeksRange.filter(
            elt => isInTimeframe(elt, timeframe[0], timeframe[1])
          )
        } else {
          range = nWeeksRange
        }
      }
      return range
    },
    markFields() {
      return this.fields.filter(elt => elt.showAsMark)
    },
    valueFields() {
      return this.fields.filter(elt => (!elt.showAsMark || elt.showAsMarkAndText))
    },
    days() {
      return this.range.map(elt => moment(elt).format(this.dateFormat))
    },
    visibleDates() {
      const visibleDates = []
      if (this.seanceTypeElt.isWeeklyListVisible()) {
        for (const day of this.range) {
          const dayKey = moment(day).format(this.dateFormat)
          if (moment(day).weekday() < 5) {
            visibleDates.push(day)
          } else {
            for (const superGrouper of this.elementsByGroups) {
              const dayMoments = this.dayCounterValue(superGrouper, dayKey)
              if (sum(dayMoments) > 0) {
                visibleDates.push(day)
                break
              }
            }
          }
        }
      } else if (this.seanceTypeElt.isSeveralWeeksVisible()) {
        for (const day of this.range) {
          visibleDates.push(day)
        }
      }
      return visibleDates
    },
    visibleDays() {
      return this.visibleDates.map(elt => moment(elt).format(this.dateFormat))
    },
    filteredElements() {
      return this.elements
    },
    dayTimesCounter() {
      return this.getTimesCounter(this.filteredElements)
    },
    groupByLabel() {
      if (this.groupBy && (this.groupBy.id === GroupByChoice.SchoolAndClass)) {
        return 'école définie'
      } else if (this.groupBy && (this.groupBy.id === GroupByChoice.SchoolLevel)) {
        return 'niveau scolaire défini'
      } else if (this.groupBy && (this.groupBy.id === GroupByChoice.AgeGroup)) {
        return 'groupe d\'age défini'
      }
      return ''
    },
    pakeBreakOnGroup() {
      if (this.groupBy && (this.groupBy.id === GroupByChoice.SchoolAndClass)) {
        return false
      }
      return true
    },
    elementsByGroups() {
      let superGroupers = []
      if (this.groupBy && (this.groupBy.id === GroupByChoice.SchoolAndClass)) {
        superGroupers = this.elementsBySchoolClass
      } else if (this.groupBy && (this.groupBy.id === GroupByChoice.SchoolLevel)) {
        superGroupers = this.elementsBySchoolLevelGroups
      } else if (this.groupBy && (this.groupBy.id === GroupByChoice.AllInOneGroup)) {
        superGroupers = this.allElementsInOneGroup
      } else {
        // by default AgeGroup
        superGroupers = this.elementsByAgeGroups
      }
      if (this.ignoreNoGroup) {
        superGroupers = superGroupers.filter(elt => elt.id > 0)
      }
      return superGroupers
    },
    elementsByAgeGroups() {
      if (this.isLoading(this.loadingName)) {
        return []
      }
      let ageGroups = this.ageGroups.map(elt => {
        return {
          name: elt.name,
          elements: [],
          startAge: elt.startAge,
          endAge: elt.endAge,
          id: elt.startAge,
          limits: [],
          showHeader: true,
          cssClass: 'main',
        }
      })
      ageGroups.push(
        {
          name: ageGroups.length ? 'Autres' : 'Tous',
          elements: [],
          startAge: 0,
          endAge: 0,
          id: 0,
          limits: [],
          cssClass: 'main',
        }
      )
      let ageGroupsIndex = {}
      for (let index = 0; index < ageGroups.length; index++) {
        let ageGroup = ageGroups[index]
        if (ageGroup.startAge) {
          const endAge = ageGroup.endAge ? ageGroup.endAge : 18
          for (let age = ageGroup.startAge; age <= endAge; age++) {
            ageGroupsIndex[age] = index
          }
        }
      }
      for (let element of this.filteredElements) {
        let ages = this.getAgeGroups(element)
        for (const age of ages) {
          let index = ageGroupsIndex[age]
          if (isNaN(index)) {
            index = -1
          }
          if ((index >= 0) && (index < ageGroups.length)) {
            ageGroups[index].elements.push(element)
          } else {
            ageGroups[ageGroups.length - 1].elements.push(element)
          }
        }
      }
      return [
        { id: 1, name: '', groups: ageGroups, showHeader: false, }
      ]
    },
    elementsBySchoolClass() {
      if (this.isLoading(this.loadingName)) {
        return []
      }
      let schoolClassMap = {}
      for (let schoolClass of this.schoolClasses) {
        schoolClassMap[schoolClass.id] = schoolClass.school.id
      }
      let diffCities = distinct(
        this.schoolClasses.filter(elt => elt.school && elt.school.city).map(
          elt => elt.school.city)
      ).length > 1
      let schools = [
        {
          id: 0,
          name: 'École non définie',
          groups: [
            { name: '', elements: [], id: 0, limits: [], showHeader: true, }
          ],
          showHeader: false,
        }
      ]
      let schoolClasses = [].concat(this.schoolClasses)
      schoolClasses = schoolClasses.sort(
        (schoolClass1, schoolClass2) => {
          if (schoolClass1.school.order > schoolClass2.school.order) {
            return 1
          } else if (schoolClass1.school.order < schoolClass2.school.order) {
            return -1
          } else {
            if (schoolClass1.school.name > schoolClass2.school.name) {
              return 1
            } else if (schoolClass1.school.name < schoolClass2.school.name) {
              return -1
            } else {
              if (schoolClass1.order > schoolClass2.order) {
                return 1
              } else if (schoolClass1.order < schoolClass2.order) {
                return -1
              } else {
                if (schoolClass1.name > schoolClass2.name) {
                  return 1
                } else if (schoolClass1.name < schoolClass2.name) {
                  return -1
                } else {
                  return 0
                }
              }
            }
          }
        }
      ).map(
        elt => {
          return {
            name: diffCities ? elt.fullName() : elt.name,
            elements: [],
            id: elt.id,
            limits: [],
            parent: elt.school,
            showHeader: true,
            textColor: elt.textColor,
            backgroundColor: elt.backgroundColor,
          }
        }
      )
      let schoolGroupsIndex = { '0#0': [0, 0], }
      for (let index = 0; index < schoolClasses.length; index++) {
        let schoolClass = schoolClasses[index]
        let schoolClassIndex = 0
        let schoolIndex = schools.map(elt => elt.id).indexOf(schoolClass.parent ? schoolClass.parent.id : 0)
        if (schoolIndex < 0) {
          schools.push(
            {
              id: schoolClass.parent ? schoolClass.parent.id : 0,
              name: schoolClass.parent ? schoolClass.parent.name : '',
              groups: [schoolClass],
              showHeader: true,
            }
          )
          schoolIndex = schools.length - 1
          schoolClassIndex = 0
        } else {
          schools[schoolIndex].groups.push(schoolClass)
          schoolClassIndex = schools[schoolIndex].groups.length - 1
        }
        const key = '' + schoolClass.parent.id + '#' + schoolClass.id
        schoolGroupsIndex[key] = [schoolIndex, schoolClassIndex]
      }
      for (let element of this.filteredElements) {
        let schoolClassIndex = 0
        let schoolIndex = 0
        if (element.child.schoolClass) {
          const schoolClassId = element.child.schoolClass.id
          let schoolId = 0
          if (schoolClassId > 0) {
            schoolId = schoolClassMap[schoolClassId]
          }
          const key = '' + schoolId + '#' + schoolClassId
          schoolIndex = schoolGroupsIndex[key][0]
          schoolClassIndex = schoolGroupsIndex[key][1]
        }
        if ((isNaN(schoolIndex)) || (schoolIndex < 0) || (schoolIndex > schools.length)) {
          schoolIndex = 0
        }
        let schoolClasses = schools[schoolIndex].groups
        if ((isNaN(schoolClassIndex)) || (schoolClassIndex < 0) || (schoolClassIndex > schoolClasses.length)) {
          schoolClassIndex = 0
          schoolIndex = 0
          schoolClasses = schools[schoolIndex]
        }
        schoolClasses[schoolClassIndex].elements.push(element)
      }
      return schools
    },
    elementsBySchoolLevelGroups() {
      if (this.isLoading(this.loadingName)) {
        return []
      }
      let schoolLevelGroups = this.schoolLevelGroups.map(elt => {
        return {
          name: elt.name,
          elements: [],
          id: elt.id,
          showHeader: true,
          limits: [],
          cssClass: 'main',
        }
      })
      const notDefinedGroup = {
        name: 'Non défini',
        elements: [],
        id: 0,
        showHeader: true,
        limits: [],
        cssClass: 'main',
      }
      schoolLevelGroups.push(notDefinedGroup)
      let schoolLevelGroupsIndex = {}
      for (let index = 0; index < schoolLevelGroups.length; index++) {
        let schoolLevelGroup = schoolLevelGroups[index]
        schoolLevelGroupsIndex[schoolLevelGroup.id] = index
      }
      for (let element of this.filteredElements) {
        let schoolLevelGroupId = element.child.schoolLevelGroup ? element.child.schoolLevelGroup.id : 0
        let index = schoolLevelGroupsIndex[schoolLevelGroupId]
        if (isNaN(index)) {
          index = -1
        }
        if ((index >= 0) && (index < schoolLevelGroups.length)) {
          schoolLevelGroups[index].elements.push(element)
        } else {
          notDefinedGroup.elements.push(element)
        }
      }
      return [
        { id: 1, name: '', groups: schoolLevelGroups, showHeader: false, }
      ]
    },
    allElementsInOneGroup() {
      if (this.isLoading(this.loadingName)) {
        return []
      }
      const mainGroup = {
        name: 'Tous',
        elements: this.filteredElements,
        id: 1,
        showHeader: true,
        limits: [],
        cssClass: 'main',
      }
      return [
        { id: 1, name: '', groups: [mainGroup], showHeader: false, }
      ]
    },
    individualIds() {
      return this.elements.map(elt => elt.individual.id)
    },
    getLinks() {
      const links = []
      if (this.seanceTypeElt.isSeveralWeeksVisible()) {
        let weeksLabel = '' + this.seanceTypeElt.weeksNumber
        if (this.seanceTypeElt.weeksNumber > 1) {
          weeksLabel += ' semaines'
        } else {
          weeksLabel += ' semaine'
        }
        links.push(
          {
            id: 2,
            label: weeksLabel,
            callback: this.showModal,
            cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
          }
        )
      }
      links.push(
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      )
      links.push(
        {
          id: 3,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'far fa-file-excel',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      )
      return links
    },
    secondColStyle() {
      if (this.printMode) {
        return 'width: 200px;'
      } else {
        return 'width: 300px;'
      }
    },
    tableStyle() {
      return 'min-width: 100%; width: 100%; margin-bottom: 30px;'
    },
    superTableStyle() {
      return 'min-width: 100%; width: 100%;'
    },
    hasOpeningHours() {
      for (const openingHours of this.openingHoursMap.values()) {
        const has = !!(
          openingHours.openingAt ||
          openingHours.closingAt ||
          openingHours.openingAt2 ||
          openingHours.closingAt2 ||
          openingHours.openingAt3 ||
          openingHours.closingAt3
        )
        if (has) {
          return true
        }
      }
      return false
    },
    clockingColumns() {
      if (this.youthHomeElt.overrideClockingColumns) {
        return this.youthHomeElt.clockingColumns
      } else {
        return this.seanceTypeElt.clockingColumns
      }
    },
  },
  mounted() {
    this.seanceDateAsString = this.day
    const groupById = +(window.localStorage.getItem(groupByKey) || 0)
    const selected = this.groupByChoices.filter(elt => elt.id === groupById)
    if (selected.length) {
      this.groupBy = selected[0]
    } else if (this.groupByChoices.length) {
      this.groupBy = this.groupByChoices[0]
    }
    this.ignoreNoGroup = !!(window.localStorage.getItem(ignoreNoGroupKey) || '')
    this.includeAllChildren = !!(window.localStorage.getItem(includeAllChildrenKey) || '')
    this.landscape = (window.localStorage.getItem(landscapeKey) || '') !== 'portrait'
    this.pageBreak = (window.localStorage.getItem(pageBreakKey) || '') !== 'no'
    this.showTotal = (window.localStorage.getItem(showTotalKey) || '') !== 'no'
    this.showParents = (window.localStorage.getItem(showParentsKey) || '') !== 'no'
    this.showFields = (window.localStorage.getItem(showFieldsKey) || '') !== 'no'
    this.clockStatus = 0
    this.onLoaded()
  },
  methods: {
    getAgeAtSeance,
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    noInscriptionOnThisDay(element, day) {
      const dayInscriptions = this.onlyDailyInscriptions(element.inscriptions, day)
      return (dayInscriptions.length === 0)
    },
    notInGroup(grouper, element, day) {
      const dayInscriptions = this.onlyDailyInscriptions(element.inscriptions, day)
      if ((grouper.startAge)) {
        const ages = this.getInscriptionAgeGroups(element, dayInscriptions)
        let existIn = false
        let endAge = grouper.endAge || 21
        for (const age of ages) {
          if ((age >= grouper.startAge) && (age <= endAge)) {
            existIn = true
            break
          }
        }
        return !existIn
      }
      return false
    },
    refreshCounters() {
      const countByDaysAndGroup = new Map()
      for (const superGrouper of this.elementsByGroups) {
        for (const grouper of superGrouper.groups) {
          const dayGroups = this.getCounterByDay(grouper)
          countByDaysAndGroup.set(grouper.id, dayGroups)
        }
      }
      this.countByDaysAndGroup = countByDaysAndGroup
    },
    getOpeningHours(day) {
      const key = moment(day).format('YYYY-MM-DD')
      if (this.openingHoursMap.has(key)) {
        return this.openingHoursMap.get(key)
      }
      return null
    },
    getInscriptionAgeGroups(element, inscriptions) {
      let ageGroups = distinct(inscriptions.map(elt => elt.ageGroup).filter(elt => elt !== null))
      if (ageGroups.length === 0) {
        const defaultAgeGroup = getAgeGroup(element.individual, element.child, this.seanceDate, this.youthHomeElt)
        return [defaultAgeGroup]
      }
      return ageGroups.map(elt => elt.startAge)
    },
    getInscriptionAges(element, inscriptions) {
      let ageGroups = distinct(inscriptions.map(elt => elt.ageGroup).filter(elt => elt !== null))
      if (ageGroups.length === 0) {
        return []
      }
      let ages = []
      for (const elt of ageGroups) {
        let age = elt.startAge
        let endAge = elt.endAge || 21
        while (age <= endAge) {
          ages.push(age)
          age += 1
        }
      }
      return ages
    },
    getAgeGroups(element) {
      return this.getInscriptionAgeGroups(element, element.inscriptions)
    },
    async onLoaded() {
      this.showBirthDate = this.getShowBirthDate()
      this.showAge = this.getShowAge()
      await this.loadInscriptions()
    },
    getShowBirthDate() {
      return (window.localStorage.getItem('showBirthDate') || 'N') === 'O'
    },
    getShowAge() {
      return (window.localStorage.getItem('showAge') || 'O') === 'O'
    },
    toggleBirthDate() {
      if (this.showAge && this.showBirthDate) {
        this.showBirthDate = false
      } else {
        if (this.showAge) {
          this.showAge = false
          this.showBirthDate = true
        } else {
          this.showAge = true
        }
      }
      if (!this.showAge && !this.showBirthDate) {
        this.showAge = true
      }
      window.localStorage.setItem('showBirthDate', this.showBirthDate ? 'O' : 'N')
      window.localStorage.setItem('showAge', this.showAge ? 'O' : 'N')
    },
    showModal() {
      this.$bvModal.show('bv-modal-edit-weeks-number')
      this.weeksNumber = this.seanceTypeElt.weeksNumber
    },
    async onSaveWeeksNumber() {
      const data = {
        'weeks_number': this.weeksNumber,
      }
      const url = '/api/youth/seance-types/' + this.seanceTypeElt.id + '/'
      let backendApi = new BackendApi('patch', url)
      try {
        let resp = await backendApi.callApi(data)
        await this.addSuccess(resp.data.message)
        this.$bvModal.hide('bv-modal-edit-weeks-number')
        await this.loadInscriptions()
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    getPhoneNumber(elt, parent) {
      if (parent.cellPhone) {
        return parent.cellPhone
      } else if (parent.proPhone) {
        return parent.proPhone
      } else if (elt.entity && elt.entity.phone) {
        return elt.phone
      }
      return '-'
    },
    getDailyPresences(grouper, element, day) {
      let inscriptions = this.getDailyInscriptions(grouper, element, day)
      let presences = []
      if (inscriptions.length >= 3) {
        presences = [
          { index: DayTimeValues.Morning, label: 'Matin', value: inscriptions[0], },
          { index: DayTimeValues.Afternoon, label: 'Après-midi', value: inscriptions[2], }
        ]
        if (this.hasLunch) {
          presences.push({ index: DayTimeValues.Lunch, label: 'Repas', value: inscriptions[1], })
        }
        if (inscriptions.length >= 4 && this.hasEvening) {
          presences.push({ index: DayTimeValues.Evening, label: 'Soirée', value: inscriptions[3], })
        }
        if (inscriptions.length >= 5 && this.hasPicnic) {
          presences.push({ index: DayTimeValues.Picnic, label: 'Pique-Nique', value: inscriptions[4], })
        }
      }
      const ordering = [
        DayTimeValues.None, DayTimeValues.Morning, DayTimeValues.Lunch, DayTimeValues.Picnic,
        DayTimeValues.Afternoon, DayTimeValues.Evening
      ]
      return presences.sort(
        (val1, val2) => {
          return compareNumbers(ordering.indexOf(val1.index), ordering.indexOf(val2.index))
        }
      )
    },
    onlyDailyInscriptions(inscriptions, day) {
      return this.filterInscriptions(inscriptions).filter(
        inscription => dateToString(inscription.seance.date, this.dateFormat) === day
      )
    },
    filterInscriptions(inscriptions) {
      if (this.seancesFilterId === 1) {
        // Sorties
        inscriptions = inscriptions.filter(ins => ins.seance.excursion === Excursion.Trip
        )
      } else if (this.seancesFilterId === 2) {
        // sauf sorties
        inscriptions = inscriptions.filter(ins => ins.seance.excursion !== Excursion.Trip
        )
      } else if (this.seancesFilterId === 3) {
        // Séjours
        inscriptions = inscriptions.filter(ins => ins.seance.excursion === Excursion.Stay
        )
      } else if (this.seancesFilterId === 4) {
        // sauf Séjours
        inscriptions = inscriptions.filter(ins => ins.seance.excursion !== Excursion.Stay
        )
      } else if (this.seancesFilterId === 5) {
        // Séjours et sorties
        inscriptions = inscriptions.filter(ins => ins.seance.excursion !== Excursion.None
        )
      } else if (this.seancesFilterId === 6) {
        // sauf Séjours et sorties
        inscriptions = inscriptions.filter(ins => ins.seance.excursion === Excursion.None
        )
      }
      return inscriptions
    },
    getDailyInscriptions(grouper, element, day) {
      let morning = 0
      let lunch = 0
      let afternoon = 0
      let evening = 0
      let picnic = 0
      const inscriptions = this.onlyDailyInscriptions(element.inscriptions, day)
      if (!this.notInGroup(grouper, element, day)) {
        for (const inscription of inscriptions) {
          const isPicnic = inscription.seance.picnic
          let isInGroup = true
          if (inscription.seance.morning) {
            morning = 1
            if (inscription.absence) {
              morning = 2
            }
          }
          if (inscription.seance.lunch && !isPicnic) {
            lunch = 1
            if (inscription.absence) {
              lunch = 2
            }
          }
          if (inscription.seance.lunch && isPicnic) {
            picnic = 1
            if (inscription.absence) {
              picnic = 2
            }
          }
          if (inscription.seance.afternoon) {
            afternoon = 1
            if (inscription.absence) {
              afternoon = 2
            }
          }
          if (inscription.seance.evening) {
            evening = 1
            if (inscription.absence) {
              evening = 2
            }
          }
        }
      }
      return [morning, lunch, afternoon, evening, picnic]
    },
    getLinkToEntity(elt) {
      return {
        name: 'families-detail',
        params: {
          entityId: '' + elt.entity.id,
        },
        query: {
          individual: '' + elt.individual.id,
        },
      }
    },
    showFieldValue(element, field) {
      // Pour certains champs, nous n'affichons que si la valeur est vraie ou fausse
      if ((!field.falseOnly) && (!field.trueOnly)) {
        return true
      }
      let value = false
      if (field.field in element.fieldValues) {
        value = !!element.fieldValues[field.field]
      }
      if (field.falseOnly && value) {
        return false
      }
      if (field.trueOnly && !value) {
        return false
      }
      return true
    },
    async loadInscriptions() {
      this.startLoading(this.loadingName)
      let dateAsTr = this.seanceDateAsString
      let url = '/api/youth/youth-homes-week/' + dateAsTr + '/' + this.youthHome + '/' + this.seanceType +
        '/' + this.seancePeriod + '/'
      if (this.includeAllChildren) {
        url += '?all=1'
      }
      const backendApi = new BackendApi('get', url)
      try {
        this.seances = []
        this.workshops = []
        let seanceIds = []
        let workshopIds = []
        const resp = await backendApi.callApi()
        this.fields = resp.data.fields.map(elt => makeDailyListField(elt))
        this.ageGroups = resp.data['age_groups'].map(elt => makeAgeGroup(elt))
        this.schoolClasses = resp.data['school_classes'].map(elt => makeSchoolClass(elt))
        this.schoolLevelGroups = resp.data['school_level_groups'].map(elt => makeSchoolLevelGroup(elt))
        this.youthHomeElt = makeYouthHome(resp.data['youth_home'])
        this.seanceTypeElt = makeSeanceType(resp.data['seance_type'])
        this.seancePeriodElt = makeSeancePeriod(resp.data['seance_period'])
        this.options = makeDayListOptions(resp.data.options)
        this.openingHoursMap = new Map()
        for (const [key, value] of Object.entries(resp.data['opening_hours'])) {
          this.openingHoursMap.set(key, makeOpeningHours(value))
        }
        this.inscriptionsCount = resp.data['inscriptions_count'] || 0
        if (this.clockingColumns.length) {
          this.displayById = 4 // showClockingCols
        }
        this.elements = resp.data.elements.map(
          elt => {
            let individual = makeIndividual(elt['individual'])
            let child = makeChild(elt['child'])
            let inscriptions = elt['inscriptions'].map(
              elt2 => {
                elt2['individual'] = individual
                return makeSeanceInscription(elt2)
              }
            )
            let entity = null
            if (inscriptions.length) {
              entity = inscriptions[0].entity
            }
            let parents = inscriptions.reduce(
              (allParents, inscription) => {
                return allParents.concat(inscription.parents)
              },
              []
            )
            let inscriptionsSeances = inscriptions.map(elt => elt.seance)
            this.seances = this.seances.concat(
              inscriptionsSeances.reduce(
                (seances, seance) => {
                  if (seanceIds.indexOf(seance.id) < 0) {
                    seanceIds.push(seance.id)
                    seances.push(seance)
                  }
                  return seances
                },
                []
              )
            )
            this.workshops = this.workshops.concat(
              inscriptionsSeances.reduce(
                (workshops, seance) => {
                  for (let workshop of seance.workshops) {
                    if (workshopIds.indexOf(workshop.id) < 0) {
                      workshopIds.push(workshop.id)
                      workshops.push(workshop)
                    }
                  }
                  return workshops
                },
                []
              )
            )
            return {
              individual: individual,
              entity: entity,
              inscriptions: inscriptions,
              parents: distinct(parents),
              fieldTexts: elt['field_texts'],
              fieldValues: elt['field_values'],
              child: child,
            }
          }
        )
        const presenceItems = resp.data['presence_items'] || []
        this.presenceItems = presenceItems.map(elt => makeListingPresenceItem(elt))
        if (this.presenceItems.length) {
          this.displayById = 5 // presences
        }
        this.inscriptionsLoaded = true
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    getSuperGrouperCount(superGrouper) {
      let total = 0
      for (let group of superGrouper.groups) {
        total += group.elements.length
      }
      return total
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'semaine-' + this.seanceDateAsString
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async printMe() {
      const printMode = this.printMode
      this.printMode = true
      this.printDate = dateToString(moment().toDate(), 'DD/MM/YYYY')
      const that = this
      setTimeout(
        async function() {
          let docUrl = '/documents/standard/<key>/pdf/'
          docUrl += '?landscape=' + (+that.landscape) + '&colors=1&pageBreak=' + (+that.pageBreak)
          const docSlug = 'semaine-' + that.seanceDateAsString
          const docContent = that.$refs.printMe.innerHTML.toString()
          try {
            await openDocument(docUrl, docSlug, docContent)
          } catch (err) {
            await that.addError(this.getErrorText(err))
          }
          that.printMode = printMode
          that.printDate = ''
        },
        200
      )
    },
    hasCustomGroup(elt) {
      return !!getCustomAge(elt.child)
    },
    getCustomGroup(elt) {
      return getCustomAge(elt.child)
    },
    grouperCounter(grouper) {
      return grouper.elements.filter(elt => !this.isAbsence(elt)).length
    },
    dayCounterValue(day) {
      const totalMoments = [0, 0, 0, 0, 0]
      for (const superGrouper of this.elementsByGroups) {
        for (const grouper of superGrouper.groups) {
          const countByDay = this.countByDaysAndGroup.get(grouper.id)
          if (countByDay && countByDay.has(day)) {
            const moments = countByDay.get(day)
            for (let index = 0; index < 5; index++) {
              totalMoments[index] += moments[index]
            }
          }
        }
      }
      return totalMoments
    },
    dayCounter(superGrouper, day) {
      return this.dayCounterValue(superGrouper, day)
    },
    groupAndDayCounter(grouper, day) {
      const countByDay = this.countByDaysAndGroup.get(grouper.id)
      if (countByDay && countByDay.has(day)) {
        return countByDay.get(day)
      }
      return []
    },
    superGroupAndDayCounter(superGrouper, day) {
      let superCountByDay = []
      for (const grouper of superGrouper.groups) {
        const values = this.groupAndDayCounter(grouper, day)
        for (let index = 0; index < values; index++) {
          if (index < superCountByDay.length) {
            superCountByDay[index] += values[index]
          } else {
            superCountByDay.push(values[index])
          }
        }
      }
      return superCountByDay
    },
    getCounterByDay(grouper) {
      const countByDay = new Map()
      for (const elt of grouper.elements) {
        for (const dayKey of this.days) {
          let moments
          if (!countByDay.has(dayKey)) {
            moments = [0, 0, 0, 0, 0]
          } else {
            moments = countByDay.get(dayKey)
          }
          const result = this.getDailyInscriptions(grouper, elt, dayKey)
          for (let index = 0; index < result.length; index++) {
            if (result[index] === 1) {
              moments[index] += 1
            }
          }
          countByDay.set(dayKey, moments)
        }
      }
      return countByDay
    },
    isAbsence(elt) {
      return elt.inscriptions.filter(inscription => !inscription.absence).length === 0
    },
    getSuperGrouperGroups(superGrouper) {
      return superGrouper.groups.filter(group => group.elements.length > 0)
    },
    onRefreshInscription(elt, event) {
      const individual = elt.individual
      const newInscription = event
      const replaced = true
      const index1 = this.elements.map(elt => elt.individual.id).indexOf(individual.id)
      if (index1 >= 0) {
        const element = this.elements[index1]
        const index2 = element.inscriptions.map(elt => elt.id).indexOf(newInscription.id)
        if (index2 >= 0) {
          element.inscriptions[index2] = newInscription
        } else {
          element.inscriptions.push(newInscription)
        }
        if (replaced) {
          const index3 = element.inscriptions.map(elt => elt.id).indexOf(replaced.id)
          if (index3 >= 0) {
            element.inscriptions.splice(index3, 1)
          }
        }
        this.elements[index1] = { ...element, }
      }
      this.elements = [].concat(this.elements)
    },
    toggleClockSelector() {
      if (this.clockStatus === 2) {
        this.clockStatus = 0
      } else {
        this.clockStatus = 2
      }
    },
    getEltName() {
      return [this.youthHomeElt.name, this.seanceTypeElt.name, this.seancePeriodElt.name].map(
        itm => itm.trim()
      ).filter(
        itm => (itm && itm !== '-')
      ).join(' - ')
    },
    getPresence(col, element) {
      const inscriptions = this.onlyDailyInscriptions(element.inscriptions, col.day)
      const isIn = inscriptions.filter(ins => col.item.isPresent(ins.seance)).length > 0
      return isIn ? '1' : ''
    },
    getPresenceSum(col, elements) {
      let total = 0
      for (const element of elements) {
        const inscriptions = this.onlyDailyInscriptions(element.inscriptions, col.day)
        const isIn = inscriptions.filter(ins => col.item.isPresent(ins.seance)).length > 0
        if (isIn) {
          total += 1
        }
      }
      return total
    },
    getSuperPresenceSum(col, superGrouper) {
      let total = 0
      for (const grouper of superGrouper.groups) {
        total += this.getPresenceSum(col, grouper.elements)
      }
      return total
    },
    getPresenceTotal(col) {
      let total = 0
      for (const superGrouper of this.elementsByGroups) {
        total += sum(superGrouper.groups.map(grouper => this.getPresenceSum(col, grouper.elements)))
      }
      return total
    },
    presenceStyle(day) {
      const odd = this.visibleDays.indexOf(day) % 2 === 0
      let style = {
        width: '45px',
        'text-align': 'center',
      }
      if (this.printMode) {
        style = {
          width: '15px',
          'text-align': 'center',
        }
        if (!odd) {
          style.background = '#eee !important'
        }
      }
      return style
    },
    dayCellStyle(day) {
      let style = this.dayColStyle(day)
      if (this.clocking || this.absence) {
        style['text-align'] = 'right !important'
      }
      return style
    },
    dayColStyle(day) {
      let style = {}
      const odd = this.visibleDays.indexOf(day) % 2 === 0
      if (this.printMode) {
        style = {
          width: '' + Math.ceil(500 / this.visibleDays.length) + 'px !important',
        }
        if (!odd) {
          style.background = '#eee !important'
        }
      }
      return style
    },
    dayClass(day) {
      const odd = this.visibleDays.indexOf(day) % 2 === 0
      return odd ? 'col-odd' : 'col-even'
    },
    async loadCafModels() {
      if (!this.cafModelsLoaded) {
        const seances = this.seances.map(elt => '' + elt.id).join('-')
        let url = '/reports/api/seance-caf-models/?seances=' + seances
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          for (const elt of resp.data) {
            this.cafModelsMap.set(+elt.seance, makeCafModel(elt.model))
          }
          this.cafModelsLoaded = true
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    getCafModel(inscription) {
      return this.cafModelsMap.get(inscription.seance.id) || null
    },
    getRealDuration(col, elt) {
      const inscriptions = this.onlyDailyInscriptions(elt.inscriptions, col.day)
      return sum(
        inscriptions.map(
          ins => calculateRealDuration(ins, this.getCafModel(ins))
        )
      )
    },
    getPaidDuration(col, elt) {
      const inscriptions = this.onlyDailyInscriptions(elt.inscriptions, col.day)
      return sum(
        inscriptions.map(
          ins => calculatePaidDuration(ins, this.getCafModel(ins))
        )
      )
    },
    getDurationLabel(col) {
      if (col.item === 1) {
        return 'Réel'
      } else if (col.item === 2) {
        return 'Fact'
      }
      return ''
    },
    getDurationSum(col, grouper) {
      if (col.item === 1) {
        return sum(grouper.elements.map(elt => this.getRealDuration(col, elt)))
      } else if (col.item === 2) {
        return sum(grouper.elements.map(elt => this.getPaidDuration(col, elt)))
      }
      return 0
    },
    getDurationTotal(col) {
      let total = 0
      for (const superGrouper of this.elementsByGroups) {
        total += sum(superGrouper.groups.map(grouper => this.getDurationSum(col, grouper)))
      }
      return total
    },
    getDurationValue(col, elt) {
      if (col.item === 1) {
        return this.getRealDuration(col, elt)
      } else if (col.item === 2) {
        return this.getPaidDuration(col, elt)
      }
      return 0
    },
    getFirstColStyle(grouper) {
      const cssStyle = {}
      if (this.printMode) {
        if (this.showFields) {
          cssStyle.width = '200px'
        } else {
          cssStyle.width = '200px'
        }
      } else {
        cssStyle.width = '300px'
      }
      if (grouper) {
        if (grouper.backgroundColor) {
          cssStyle.backgroundColor = grouper.backgroundColor
        }
        if (grouper.textColor) {
          cssStyle.color = grouper.textColor
        }
      }
      return cssStyle
    },
  },
}
</script>

<style lang="less" scoped>
.highlight {
  font-weight: bold;
  color: #000;
  background: #e35e5e;
  font-size: 12px;
  margin: 2px 5px;
  padding: 3px;
  border-radius: 2px;
}

.super-grouper {
  margin-bottom: 30px;
}

.super-grouper-header {
  padding: 10px;
  background: #aaa;
  font-weight: bold;
}
.super-grouper-header a, .grouper-header a  {
  margin-left: 3px;
}
.grouper-header {
  margin: 0;
  padding: 10px;
  background: #ccc;
  font-weight: bold;
}
.grouper {
  margin-bottom: 0;
}
.grouper.main {
  margin-bottom: 20px;
}
.grouper.main .grouper-header {
  background: #bbb;
}

.sub-header {
  margin-bottom: 10px;
}
.select-separator {
  margin-bottom: 5px;
}
.inscription-line {
  padding-bottom: 2px;
  margin-bottom: 2px;
  border-bottom: solid 1px #aaa;
}
.inscription-line:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}
.limit-warning {
  background: #e8c67c;
  padding: 2px 4px;
  color: #000;
  display: block;
  font-size: 11px;
}
.fa-exclamation-triangle {
  font-size: 16px;
}
.filter-element {
  border-radius: 2px;
  padding: 2px;
  font-size: 12px;
  margin: 1px;
  background: #444;
  color: #fff;
}
.checkbox-selector {
  padding: 3px;
  border: solid 1px #ccc;
  margin: 3px 0;
}
.day-times-counter {
  font-size: 10px;
  padding: 3px;
  border: solid 1px #e0e0e0;
  margin-left: 3px;
  display: inline-block;
}
.grouper-counter {
  display: inline-block;
  vertical-align: top;
}
.grouper-toggle {
  display: inline-block;
  vertical-align: top;
}
.inscription-moment-indicator {
  padding: 0;
  margin-right: 0;
}
.table-full {
  min-width: 100%;
}
.table-full tr td, .table-full tr th {
  border: solid 1px #222;
}
.table-header th{
  background: #888;
  font-weight: bold;
}
.table-total {
  margin-bottom: 30px;
}
.presences-indicator {
  font-size: 20px;
  display: inline-block;
  margin: 0 1px;
  color: #222;
}
.words > span {
  margin-right: 2px;
}
.seance-name {
  font-size: 11px;
  margin-right: 2px;
}
.rotate-90 {
  writing-mode: sideways-lr;
  padding-left: 10px;
}
.table-vertical {
  vertical-align: middle;
  text-align: center;
}
.super-grouper-header th {
  background: #bbb !important;
}
.super-grouper-header th.col-even {
  background: #8ab5b5 !important;
}
th.col-even {
  background: #a2d7d7 !important;
}
td.col-even {
  background: #b4e9ea !important;
}
.super-grouper-header th.col-odd {
  background: #b1b28e !important;
}
th.col-odd {
  background: #e6e9ba !important;
}
td.col-odd {
  background: #fdffd1 !important;
}
.number {
  text-align: center;
}
</style>
