<template>
  <span class="moments-counter" v-if="totalMoments.length">
    <span title="Matin" v-b-tooltip.hover>
      {{ totalMoments[0] }}
    </span>
    <span title="Repas" v-b-tooltip.hover v-if="hasLunch">
      {{ totalMoments[1] }}
    </span>
    <span title="Pique-Nique" v-b-tooltip.hover v-if="totalMoments.length >= 5 && totalMoments[4]">
      {{ totalMoments[4] }}
    </span>
    <span title="Après-midi" v-b-tooltip.hover>
      {{ totalMoments[2] }}
    </span>
        <span title="Soirée" v-b-tooltip.hover v-if="totalMoments.length >= 4 && totalMoments[3]">
      {{ totalMoments[3] }}
    </span>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

export default {
  name: 'MomentsCounter',
  props: {
    totalMoments: Array,
    hasLunch: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
<style lang="less">
.moments-counter > span{
  font-size: 12px;
  padding: 1px;
  border: solid 1px #888;
  display: inline-block;
  min-width: 20px;
  margin: 1px;
  color: #444;
}
</style>
